import { useHaimerPhotos } from "shared/Hooks/haimer/useHaimerPhotos";

export const useHaimerData = () => {
  const photos = useHaimerPhotos();
  return {
    metaInfo: {
      title: "Haimer",
      ogDescription:
        "Lider europejskiego rynku technologii uchwytów narzędziowych/tool holding. Dostarcza rozwiązania najwyższej jakości, które cechuje innowacyjność poparta bogatym doświadczeniem. Produkty firmy Haimer podniosą efektywność, bezpieczeństwo i ekonomię działań w Twojej firmie.",
      ogImage: photos.logo.childImageSharp.fluid.src,
    },
    hero: {
      title: " Haimer - najwyższa jakość",
      subtitle:
        "Lider europejskiego rynku technologii uchwytów narzędziowych/tool holding. Dostarcza rozwiązania najwyższej jakości, które cechuje innowacyjność poparta bogatym doświadczeniem. Produkty firmy Haimer podniosą efektywność, bezpieczeństwo i ekonomię działań w Twojej firmie.",
      bg: photos.hero,
    },
    measuring: {
      title: "Czujniki 3D",
      subtitle:
        "Szybkie i precyzyjne pozycjonowanie i mierzenie obrabianego materiału.",
      links: [
        {
          text: "Pobierz katalog",
          url: "/catalogs/haimer/haimer-measuring.pdf",
        },
      ],
      bigImg: true,
      src: photos.img1,
    },
    toolholder: {
      title: "Uchwyty narzędziowe",
      subtitle:
        "Uchwyty dostosowane do potrzeb każdego rodzaju przemysłu: bez względu na to, czy potrzebujesz większej elastyczności, minimalizacji wibracji czy dużej siły tnącej.",
      links: [
        {
          text: "Pobierz katalog",
          url: "/catalogs/haimer/haimer-toolholders.pdf",
        },
      ],
      bigImg: true,
      src: photos.img2,
    },
    shrinking: {
      title: "Technologie zamocowań",
      subtitle:
        "Pozwalające na łatwą i szybką zmianę używanego narzędzia, jednocześnie zapewniając precyzyjne i mocne trzymanie.",
      links: [
        {
          text: "Pobierz katalog",
          url: "/catalogs/haimer/haimer-shrinking.pdf",
        },
      ],
      bigImg: true,
      src: photos.img3,
    },
    presetting: {
      title: "Technologie pomiarowe narzędzi",
      subtitle:
        "Bezpośredni transfer danych do maszyny pozwala zwiększyć bezpieczeństwo samego procesu programowania narzędzia, a także wzmaga produktywność.",
      links: [
        {
          text: "Pobierz katalog",
          url: "/catalogs/haimer/haimer-presetting.pdf",
        },
      ],
      bigImg: true,
      src: photos.img4,
    },
    balancing: {
      title: "Technologie wyważania",
      subtitle:
        "Większa produktywność, dłuższa żywotność narzędzia, duże bezpieczeństwo - dzięki większej równowadze w procesie produkcji.",
      links: [
        {
          text: "Pobierz katalog",
          url: "/catalogs/haimer/haimer-balancing.pdf",
        },
      ],
      bigImg: true,
      src: photos.img5,
    },
    grinding: {
      title: "Adaptery do szlifierek",
      subtitle:
        "Adaptery podnoszące parametry szlifowania i zapobiegające spadkowi produktywności.",
      links: [
        {
          text: "Pobierz katalog",
          url: "/catalogs/haimer/haimer-grinding.pdf",
        },
      ],
      bigImg: true,
      src: photos.img6,
    },
    tooling: {
      title: "Narzędzia",
      subtitle:
        "Najwyższej jakości technologia narzędziowa dla maksymalnej precyzji działania.",
      links: [
        {
          text: "Pobierz katalog",
          url: "/catalogs/haimer/haimer-tooltechnology.pdf",
        },
      ],
      bigImg: true,
      src: photos.img7,
    },
  };
};

import React from "react";
import { useHaimerData } from "lang/haimer";
import Haimer from "sections/Haimer/Haimer";

const HaimerPage = () => {
  const data = useHaimerData();
  return <Haimer data={data} />;
};

export default HaimerPage;
